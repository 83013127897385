import React, { useContext } from "react";
import { Link, useNavigation } from "react-router-dom";
import { SlotData } from "../../Utils/constants";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import { isEmpty } from "lodash";
import { BannerSliderData } from "../../Utils/constants";

const SlotSlider = () => {
  
  const { t } = useTranslation();
  const { lang, user, launchEGTCasino, launchCasino,withoutLogin } = useContext(AuthContext);
  const navigate = useNavigation();
  return (
    <>
      <div className="common-slider">
        <div className="heading-block">
          <h5>{t("Social Link")}</h5>
          {/* <Link to="/">{t("See_All")}</Link> */}
        </div>

        <div className="common-slider-box slot-slider-b">
          
          
            {BannerSliderData.map((item) => {
              return (
                <div
                  onClick={() => {
                    window.location.href=item?.link;
                  }}
                  className="slider-items"
                >
                  <figure className="mb-0">
                    <img src={item?.bannerImg} alt="" />
                  </figure>
                  <figcaption>
                    <h6> {item?.name}</h6>
                   
                  </figcaption>
                </div>
              );
            })}
          
        </div>
      </div>
    </>
  );
};

export default SlotSlider;
