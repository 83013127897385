const pathObj = {
  loginUser: "/v1/user/login",
  registerUser: "/v1/user/register",
  userSendOtp: "/v1/user/user-send-otp",
  verifyOtp: "/v1/user/verify-otp",
  refreshToken: "/v1/user/refresh-token",
  seriesList: "/v1/user/match/series",
  matchList: "/v1/user/match/mobile-list",
  fancyList: "/v1/user/match/fancy-list",
  refreshAmount: "/v1/user/refresh-amount",
  userProfile: "/v1/user/profile",
  userProfileUpdate: "/v1/user/profile-update",
  userEditStake: "/v1/user/edit-stake",
  userEditOneClickStake: "/v1/user/edit-one-click-stake",
  activityLogs: "/v1/user/activity-logs",
  changePassword: "/v1/user/change-password",
  transactionLogs: "/v1/user/transaction/logs",
  accountStatement: "/v1/user/transaction/bet-logs",
  matchDetail: "/v1/user/match/detail",
  matchScore: "/v1/user/match/score",
  sportsList: "/v1/user/sport/list",
  inPlayCount: "/v1/user/sport/inplay-count",
  inPlayCountMobile: "/v1/user/sport/mobile-inplay-count",
  betfairPlaceBet: "/v1/user/match/betfaire-place-bet",
  soccerPlaceBet: "/v1/user/match/soccer-place-bet",
  tennisPlaceBet: "/v1/user/match/tennis-place-bet",
  userInPlaySport: "/v1/user/sport/mobile",
  bookmakerBet: "/v1/user/match/bookmaker-place-bet",
  betPosition: "/v1/user/match/get-bet-positions",
  betEventsBetPosition: "/v1/user/match/get-events-bet-positions",
  multiEventPlaceBet: "/v1/user/match/multiple-event-place-bet",
  getCurrentBetsExchanges: "/v1/user/match/my-bets",
  cancelUnmatchedBets: "/v1/user/match/clear-unmatched-bet",
  getBookmakerBets: "/v1/user/match/my-bookmaker-bets",
  getFancyBets: "/v1/user/match/my-fancy-bets",
  getSportsBook: "/v1/user/match/my-sport-book-bets",
  fancyBetPlace: "/v1/user/match/fancy-place-bet",
  diamondfancyBetPlace: "/v1/user/match/diamond-fancy-place-bet",
  multiMarket: "/v1/user/sport/multi-market",
  preminumFancyBets: "/v1/user/match/premium-fancy-place-bet",
  addMatchInMultiMarket: "/v1/user/sport/multi-market",
  getPremiumFancy: "/v1/cron/premium-fancy",
  getMatchOdds: "/v1/cron/match-odds",
  getBetsData: "/v1/user/match/events-bets",
  getHistory: "/v1/user/match/bet-history",
  messageList: "/v1/user/message/list",
  userSubscribe: "/v1/user/subscribe",
  adminSetting: "/v1/user/site-setting/site-setting-list",
  searchEvent: "/v1/user/match/search-list",
  tournamentList: "/v1/user/match/list",
  currentBets: "/v1/user/match/current-bets",
  mobileCurrentBets: "/v1/user/match/mobile-current-bets",
  betFancyPosition: "/v1/user/match/fancy-bet-positions",
  bookPosition: "/v1/user/match/fancy-bet-run-positions",
  betPremiumFancyPosition: "/v1/user/match/sport-book-bet-positions",
  doLoginAndLaunchGame: "/v1/wallet/doLoginAndLaunchGame",
  casinoAmountAdd: "/v1/user/casino-amount-add",
  withdrawCasinoAmount: "/v1/wallet/withdraw",
  easytogoCasinoAmountAdd: "/v1/user/easytogo-casino-amount-add",
  doLoginAndLaunchEasyToCasino: "/v1/wallet/doLoginAndLaunchEasyToCasino",
  gameList: "/v1/wallet/gameList",
  casinoWalletLogin: "/v1/wallet/login",
  awcBalance: "/v1/wallet/get-awc-balance",
  egtBalance: "/v1/wallet/get-egt-balance",
  unMatchedBets: "/v1/user/match/current-group-unmatch-bets",
  clearBets: "/v1/user/match/clear-unmatched-bet?",
  getSetting: "/v1/user/website-setting",
  beforeBetPlace: "/v1/user/match/check-bet-price",
  easytogoCasinoAmountAdd: "/v1/user/easytogo-casino-amount-add",
  doLoginAndLaunchEasyToCasino: "/v1/wallet/doLoginAndLaunchEasyToCasino",
  getEGTCasinoAmount: "/v1/wallet/getEGTCasinoAmount",
  withdrawEgtCasinoAmount: "/v1/wallet/withdraw-egt-balance",
  profitLoss: "/v1/user/match/profit-loss",
  tossBetPlace: "/v1/user/match/place-toss-bet",
  tieBetPlace: "/v1/user/match/place-tie-bet",
  p2ptransfer: "/v1/user/transaction/p2p-transfer",
  p2ptransferLog: "/v1/user/transaction/p2p-logs",
  casinoGetResult: "/v1/wallet/client-transaction-history",
  casionStatement: "/v1/user/match/casino-bet-history",
  sendOTP: "/v1/user/send-otp",
  otpVerify: "v1/user/check-otp",
  createPin: "/v1/user/set-pin",
  depositAmount: "/v1/user/deposit-amount",
  getBank: "/v1/user/get-bank-list",
  getBankDetail: "/v1/user/get-bank-details",
  withdrawal: "/v1/user/withdrawal-amount",
  reciptUpload: "/v1/user/receipt-upload",
  getBanner:"/v1/user/get-home-banners",
  affiliate:"/v1/user/agent-register",
  getIcon:"/v1/user/website-setting",
  getParlyBets: "/v1/user/match/my-parlay-bets",

  // deposit and withdraw
  depositHistory: "/v1/user/deposit-history",
  withDrawHistory: "/v1/user/withdrawal-history",
  refralList:"/v1/user/referral-list",
  doLoginAndLaunchIQCasino: "/v1/wallet/doLoginAndLaunchIQCasino",
  getTurnover:"/v1/user/get-turnover",
  forgotVerifyOTP: "/v1/user/forgot-verify-otp",
  forgotPassword: "/v1/user/forgot-password",
};

export default pathObj;
